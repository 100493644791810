import { combineReducers } from 'redux';
import { createMultilanguageReducer } from 'redux-multilanguage';
import currencyReducer from './currencyReducer';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import wishlistReducer from './wishlistReducer';
import compareReducer from './compareReducer';
import paymentReducer from './paymentReducer';
import initPointReducer from './initPointReducer';
import discountReducer from './discountReducer';
import subscriptionReducer from './subscriptionReducer'

import filterShopReducer from './filterShopReducer'
import searchTextReducer from './searchTextReducer';
import bannerTextReducer from './bannerTextReducer'
import authReducer from './authReducer';
import metaTagsData from './metaTagsReducer'
import blogData from './blogReducer'

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: 'en' }),
  currencyData: currencyReducer,
  productData: productReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  paymentData: paymentReducer,
  initPoint: initPointReducer,
  discountReducer: discountReducer,
  subscription: subscriptionReducer,
  filterShop: filterShopReducer,
  searchText: searchTextReducer,
  bannerText: bannerTextReducer,
  authInfo: authReducer,
  metaTagsData: metaTagsData,
  blogData: blogData
});

export default rootReducer;
